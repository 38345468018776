<!-- eslint-disable-next-line vue/require-v-for-key -->
<template>
    <div class="wrapper">
        <table class="custom-table">
            <tbody>
                <tr>
                    <td class="custom-th custom-th-bg" rowspan="2" style="width:.6rem;">
                        分类
                    </td>
                    <td class="custom-th custom-th-bg" rowspan="2" style="width: 2.5rem;">
                        子类
                    </td>
                    <td class="custom-th custom-th-bg" colspan="4">
                        数量(吨)
                    </td>
                    <td class="custom-th custom-th-bg" rowspan="2">
                        价格(元/吨)
                    </td>
                    <td class="custom-th custom-th-bg" rowspan="2">
                        核定价格(元/吨)
                    </td>
                </tr>
                <tr>
                    <td class="custom-th custom-th-bg">
                        本月
                    </td>
                    <td class="custom-th custom-th-bg" style="width:1.50rem;">
                        本年累计
                    </td>
                    <td class="custom-th custom-th-bg">
                        本年计划
                    </td>
                    <td class="custom-th custom-th-bg" style="width:1.50rem;">
                        本年完成(%)
                    </td>
                </tr>
                <template v-for="(item, index) in data">
                    <tr :key="item.rpt_id" v-if="item.detail.length>0">
                        <td colspan="2" class="custom-th">
                            {{ item.procureType.type_name }}
                        </td>
                        <td :class="{'red': parseFloat(item.procureType.total)<0}">
                            {{ item.procureType.total }}
                        </td>
                        <td :class="{'red': parseFloat(item.procureType.total_year)<0}">
                            {{ item.procureType.total_year }}
                        </td>
                        <td :class="{'red': parseFloat(item.procureType.plan_year)<0}">
                            {{ item.procureType.plan_year }}
                        </td>
                        <td :class="{'red': parseFloat(item.procureType.plan_year_finish)<0}">
                            {{ item.procureType.plan_year_finish }}
                        </td>
                        <td :class="{'red': parseFloat(item.procureType.price)<0}">
                            {{ item.procureType.price }}
                        </td>
                        <td :class="{'red': parseFloat(item.procureType.price_auth)<0}">
                            {{ item.procureType.price_auth }}
                        </td>
                    </tr>
                    <tr :key="item.rpt_id" v-if="item.detail.length<=0" :class="{'border6': index < data.length-1}">
                        <td colspan="2" class="custom-th">
                            {{ item.procureType.type_name }}
                        </td>
                        <td>
                            <el-input
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :disabled="type=='approval'"
                                v-model="item.procureType.total"
                                @blur="computProcure('procureType', null, item.procureType, index, item.procureType.total)"
                                :class="{'red': parseFloat(item.procureType.total)<0}"
                            ></el-input>
                        </td>
                        <td :class="{'red': parseFloat(item.procureType.total_year)<0}">
                            {{ item.procureType.total_year }}
                        </td>
                        <td>
                            <el-input
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :disabled="type=='approval'"
                                v-model="item.procureType.plan_year"
                                @blur="computProcure('procureType', null, item.procureType, index, item.procureType.plan_year)"
                                :class="{'red': parseFloat(item.procureType.plan_year)<0}"
                            ></el-input>
                        </td>
                        <td :class="{'red': parseFloat(item.procureType.plan_year_finish)<0}">
                            {{ item.procureType.plan_year_finish }}
                        </td>
                        <td>
                            <el-input
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :disabled="type=='approval'"
                                v-model="item.procureType.price"
                                :class="{'red': parseFloat(item.procureType.price)<0}"
                            ></el-input>
                        </td>
                        <td>
                            <el-input
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :disabled="type=='approval'"
                                v-model="item.procureType.price_auth"
                                :class="{'red': parseFloat(item.procureType.price_auth)<0}"
                            ></el-input>
                            <!-- {{ item.procureType.price_auth }} -->
                        </td>
                    </tr>
                    <template>
                        <tr v-for="(v, vIndex) in item.detail" :key="v.rptd_id" :class="{'border6': vIndex===item.detail.length-1}">
                            <td></td>
                            <td class="custom-th">
                                {{ v.type_name }}
                            </td>
                            <td>
                                <el-input
                                    oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                    :disabled="type=='approval'"
                                    v-model="v.total"
                                    @blur="computProcure('total', v, item.procureType, index, v.total)"
                                    :class="{'red': parseFloat(v.total)<0}"
                                ></el-input>
                            </td>
                            <td :class="{'red': parseFloat(v.total_year)<0}">
                                {{ v.total_year }}
                            </td>
                            <td>
                                <el-input
                                    oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                    :disabled="type=='approval'"
                                    v-model="v.plan_year"
                                    @blur="computProcure('plan_year', v, item.procureType, index, v.plan_year)"
                                    :class="{'red': parseFloat(v.plan_year)<0}"
                                ></el-input>
                            </td>
                            <td :class="{'red': parseFloat(v.plan_year_finish)<0}">
                                {{ v.plan_year_finish }}
                            </td>
                            <td>
                                <el-input
                                    oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                    :disabled="type=='approval'"
                                    v-model="v.price"
                                    @blur="computProcure('price', v, item.procureType, index, v.price)"
                                    :class="{'red': parseFloat(v.price)<0}"
                                ></el-input>
                            </td>
                            <td>
                                <el-input
                                    oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                    :disabled="type=='approval'"
                                    v-model="v.price_auth"
                                    @blur="computProcure('price_auth', v, item.procureType, index, v.price_auth)"
                                    :class="{'red': parseFloat(v.price_auth)<0}"
                                ></el-input>
                                <!-- {{ v.price_auth }} -->
                            </td>
                        </tr>
                    </template>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
/* eslint-disable */
import util from '@/utils/util';
export default {
    components: {},
    props: {
        reportMonth: {
            type: [Object],
        },
        type: {
            type: [String],
        },
        loadObj: {
            type: [Object],
        },
        typeList: {
            type: [Array],
        },
    },
    data() {
        return {
            data: [],
            oldData: [],
            procureRes: null, // 上个月数据
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 获取指定年月的报表实例信息
        getInstance() {
            return this.$axios.get('/interfaceApi/report/instance/get/yyqk/'
            + this.reportMonth.report_year + '/' + (this.reportMonth.report_month - 1));
        },
        // 获取物资采购上个月数据
        getProcurePreMonth(report_id) {
            return this.$axios.get('/interfaceApi/report/procure/' + report_id);
        },
        // 获取-物资采购
        getProcure() {
            this.$axios
                .get('/interfaceApi/report/procure/' + this.reportMonth.report_id)
                .then(async res => {
                    if (res) {
                        this.data = res;
                        if (this.type !== 'approval') {
                            // 核定价格初始化上个月的数据
                            if (this.typeList[4].state === 0) {
                                if (this.reportMonth.report_month > 1) {
                                    const instanceRes = await this.getInstance();
                                    if (instanceRes) {
                                        if (instanceRes.report_state === 3) {
                                            const procureRes = await this.getProcurePreMonth(instanceRes.report_id);
                                            // eslint-disable-next-line max-depth
                                            if (procureRes) {
                                                this.procureRes = procureRes;
                                                // eslint-disable-next-line max-depth
                                                res.map((item, index)=>{
                                                    item.procureType.price_auth = procureRes[index].procureType.price_auth;
                                                    item.procureType.plan_year = procureRes[index].procureType.plan_year;
                                                    item.procureType.total_year = procureRes[index].procureType.total_year;
                                                    if (item.detail.length>0) {
                                                        item.detail.map((v,vIndex)=>{
                                                            v.price_auth = procureRes[index].detail[vIndex].price_auth;
                                                            v.plan_year = procureRes[index].detail[vIndex].plan_year;
                                                            v.total_year = procureRes[index].detail[vIndex].total_year;
                                                        });
                                                    }
                                                    return item;
                                                });
                                            }
                                        }
                                    } 
                                } 
                            }
                            if (this.reportMonth.report_month > 1) {
                                const instanceRes = await this.getInstance();
                                if (instanceRes) {
                                    if (instanceRes.report_state === 3) {
                                        const procureRes = await this.getProcurePreMonth(instanceRes.report_id);
                                        if (procureRes) {
                                            this.procureRes = procureRes;
                                            res.map((item, index)=>{
                                                item.procureType.total_year_pre = this.procureRes[index].procureType.total_year
                                                if (item.detail.length>0) {
                                                    item.detail.map((v,vIndex)=>{
                                                        v.total_year_pre = this.procureRes[index].detail[vIndex].total_year;
                                                    });
                                                }
                                                return item;
                                            });
                                            this.oldData = JSON.parse(JSON.stringify(this.data));
                                        }
                                    }
                                }
                            }
                            this.oldData = JSON.parse(JSON.stringify(this.data));
                        }
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存-物资采购
        save() {
            const newData = {
                procureType: [],
                detail: [],
            };
            const _this = this;
            this.oldData.map((item, index) => {
                const procureTypeB = util.Compare(item.procureType, _this.data[index].procureType);
                if (!procureTypeB) {
                    newData.procureType.push(_this.data[index].procureType);
                }
                item.detail.map((v, vIndex) => {
                    const detailB = util.Compare(v, _this.data[index].detail[vIndex]);
                    if (!detailB) {
                        newData.detail.push(_this.data[index].detail[vIndex]);
                    }
                });
            });

            if (newData.procureType.length<1&&newData.detail.length<1) {
                this.$message.warning('暂无修改内容！');
                this.$emit('save-end', 'sales', 'validateFailed');
                return;
            }
            let procureTypeFalse = false;
            if (newData.procureType.length>0) {
                newData.procureType = newData.procureType.map(item=>{
                    for (const key in item) {
                        let notParse = false;
                        if (key === 'rpt_id' || key === 'report_id'|| key === 'type_name'|| key === 'type_code'|| key === 'sort_num') {
                            notParse = true;
                        }
                        if (!notParse) {
                            if (!item[key]) {
                                item[key] = 0;
                            } else {
                                item[key] = parseFloat(item[key]);
                            }
                            if (item[key] < 0) {
                                procureTypeFalse = true;
                            }
                        }
                    }
                    return item;
                });
            }
            let detailFalse = false;
            if (newData.detail.length>0) {
                newData.detail = newData.detail.map(item=>{
                    for (const key in item) {
                        let notParse = false;
                        if (key === 'rptd_id' || key === 'report_id'|| key === 'procure_type'|| key === 'type_code'|| key === 'type_name'|| key === 'sort_num') {
                            notParse = true;
                        }
                        if (!notParse) {
                            if (!item[key]) {
                                item[key] = 0;
                            } else {
                                item[key] = parseFloat(item[key]);
                            }
                            if (item[key] < 0) {
                                detailFalse = true;
                            }
                        }
                    }
                    return item;
                });
            }
            let isExistMinus = false;
            if (procureTypeFalse||detailFalse) {
                isExistMinus = true;
            }
            if (!isExistMinus) {
                this.$axios
                .post('/interfaceApi/report/procure/save', newData)
                .then(async res => {
                    if (res) {
                        if(res.procureType.length>0){
                            res.procureType.map(item=>{
                                this.data = this.data.map((v, index)=>{
                                    if(item.type_code === v.procureType.type_code){
                                        v.procureType = item;
                                        if (this.procureRes) {
                                            v.procureType.total_year_pre = this.procureRes[index].procureType.total_year
                                        }
                                    }
                                    return v;
                                });
                            });
                        }
                        if (res.detail.length>0) {
                            res.detail.map(item=>{
                                this.data = this.data.map((v,index)=>{
                                    v.detail = v.detail.map((detailItem,vIndex)=>{
                                        if(item.type_code === detailItem.type_code){
                                            detailItem = item;
                                            if (this.procureRes) {
                                                detailItem.total_year_pre = this.procureRes[index].detail[vIndex].total_year;
                                            }
                                        }
                                        return detailItem;
                                    });
                                    return v;
                                });
                            });
                        }
                        this.oldData = JSON.parse(JSON.stringify(this.data));
                        this.$message.success('保存成功');
                        this.$emit('save-end', 'procute', 'success');
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                    this.$emit('save-end', 'sales', 'error');
                });
            } else {
                this.$message.warning('存在负数，请重新填写！');
                this.$emit('save-end', 'sales', 'validateFailed');
            }

            
        },
        // 本年累计
        getTotalyear(code, parentCode) {
            if (!code) {
                return this.$axios.get('/interfaceApi/report/procure/totalyear/' + this.reportMonth.report_id + '/' + parentCode);
            // eslint-disable-next-line no-else-return
            } else {
                return this.$axios.get('/interfaceApi/report/procure/totalyear/' + this.reportMonth.report_id + '/' + parentCode + '/' + code);
            }
        },
        // 计算
        // eslint-disable-next-line max-params
        // eslint-disable-next-line complexity
        async computProcure(type, rowData, procureType, index, fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
            if (type === 'total') {
                if(!rowData.total){
                    rowData.total = 0;
                }
                if(!rowData.total_year_pre){
                    rowData.total_year_pre = 0;
                }
                rowData.total_year = util.toFixed6(parseFloat(rowData.total) + parseFloat(rowData.total_year_pre));
                procureType.total = 0;
                procureType.total_year = 0;
                this.data[index].detail.map(item => {
                    if (item.total ) {
                        procureType.total = util.toFixed6(parseFloat(procureType.total) + parseFloat(item.total));
                    }
                    if (item.total_year) {
                        procureType.total_year = util.toFixed6(parseFloat(procureType.total_year) + parseFloat(item.total_year));
                    }
                    if (parseFloat(item.total_year) && parseFloat(item.plan_year) ) {
                        item.plan_year_finish = util.toFixed6(parseFloat(item.total_year) / parseFloat(item.plan_year) * 100);
                    } else {
                        item.plan_year_finish = 0;
                    }
                });
                if (parseFloat(procureType.plan_year) && parseFloat(procureType.total_year)) {
                    procureType.plan_year_finish = util.toFixed6(parseFloat(procureType.total_year) / parseFloat(procureType.plan_year) * 100);
                } else {
                    procureType.plan_year_finish = 0;
                }
                // 价格相关
                procureType.price = 0;
                let sumSales = 0;
                this.data[index].detail.map(item => {
                    if (parseFloat(item.total) && parseFloat(item.price)) {
                        sumSales = util.toFixed6(parseFloat(sumSales) + parseFloat(item.total) * parseFloat(item.price));
                    }
                });
                if (parseFloat(sumSales) && parseFloat(procureType.total)) {
                    procureType.price = util.toFixed6(parseFloat(sumSales) / parseFloat(procureType.total));
                } else {
                    procureType.price = 0;
                }
                // 核定价格相关
                procureType.price_auth = 0;
                let sumPriceAuth = 0;
                this.data[index].detail.map(item => {
                    if (parseFloat(item.total) && parseFloat(item.price_auth)) {
                        sumPriceAuth = util.toFixed6(parseFloat(sumPriceAuth) + parseFloat(item.total) * parseFloat(item.price_auth));
                    }
                });
                if (parseFloat(sumPriceAuth) && parseFloat(procureType.total)) {
                    procureType.price_auth = util.toFixed6(parseFloat(sumPriceAuth) / parseFloat(procureType.total));
                } else {
                    procureType.price_auth = 0;
                }
            }
            if (type === 'plan_year') {
                if (rowData.plan_year ) {
                    procureType.plan_year = 0;
                    this.data[index].detail.map(item => {
                        if (item.plan_year ) {
                            procureType.plan_year = util.toFixed6(parseFloat(procureType.plan_year) + parseFloat(item.plan_year));
                        }
                        if (parseFloat(item.total_year) && parseFloat(item.plan_year) ) {
                            item.plan_year_finish = util.toFixed6(parseFloat(item.total_year) / parseFloat(item.plan_year) * 100);
                        } else {
                            item.plan_year_finish = 0;
                        }
                    });
                    if (parseFloat(procureType.plan_year) && parseFloat(procureType.total_year)) {
                        procureType.plan_year_finish = util.toFixed6(parseFloat(procureType.total_year) / parseFloat(procureType.plan_year) * 100);
                    } else {
                        procureType.plan_year_finish = 0;
                    }
                }
            }
            if (type === 'procureType') {
                // const res = await this.getTotalyear('', procureType.type_code);
                // if (res || res === 0) {
                    
                // }
                if (!procureType.total) {
                    procureType.total = 0;
                }
                if(!procureType.total_year_pre){
                    procureType.total_year_pre = 0;
                }
                procureType.total_year = util.toFixed6(parseFloat(procureType.total) + parseFloat(procureType.total_year_pre));
                if (parseFloat(procureType.plan_year) && parseFloat(procureType.total_year)) {
                    procureType.plan_year_finish = util.toFixed6(parseFloat(procureType.total_year) / parseFloat(procureType.plan_year) * 100);
                } else {
                    procureType.plan_year_finish = 0;
                }
            }
            // 价格相关
            if (type === 'price') {
                procureType.price = 0;
                let sumSales = 0;
                this.data[index].detail.map(item => {
                    if (parseFloat(item.total) && parseFloat(item.price)) {
                        sumSales = util.toFixed6(parseFloat(sumSales) + parseFloat(item.total) * parseFloat(item.price));
                    }
                });
                if (parseFloat(sumSales) && parseFloat(procureType.total)) {
                    procureType.price = util.toFixed6(parseFloat(sumSales) / parseFloat(procureType.total));
                } else {
                    procureType.price = 0;
                }
            }
            // 核定价格相关
            if (type === 'price_auth') {
                procureType.price_auth = 0;
                let sumPriceAuth = 0;
                this.data[index].detail.map(item => {
                    if (parseFloat(item.total) && parseFloat(item.price_auth)) {
                        sumPriceAuth = util.toFixed6(parseFloat(sumPriceAuth) + parseFloat(item.total) * parseFloat(item.price_auth));
                    }
                });
                if (parseFloat(sumPriceAuth) && parseFloat(procureType.total)) {
                    procureType.price_auth = util.toFixed6(parseFloat(sumPriceAuth) / parseFloat(procureType.total));
                } else {
                    procureType.price_auth = 0;
                }
            }
        },
    },
    created() {
        this.getProcure();
    },
    mounted() {},
};
</script>
<style lang="stylus">
.wrapper{}
</style>